import { fonts } from 'theme/fonts'
import colors from 'theme/colors'
import styled from 'styled-components'
import { mobileFirstMedia, media } from 'utils/media'

export const EditableRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: solid 2px ${colors['border']};
`

export const EditableRowContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-height: 70px;
`

export const EditableRowLabel = styled.div`
  align-items: center;
  color: ${colors['darkGrey']};

  display: ${props => (props.isHidden ? 'none' : 'flex')};

  ${media.screen`
    display: flex;
  `}

  font-size: ${fonts['text']['size']}px;
  justify-content: flex-start;
  margin-right: auto;
  overflow: hidden;
  white-space: nowrap;
  width: 126px;
`

export const EditableRowGroup = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  font-size: ${fonts['text']['size']}px;
  overflow: hidden;
  white-space: nowrap;
`

export const EditableRowLink = styled.a`
  color: ${colors['secondary']};
  font-size: ${fonts['text']['size']}px;
  line-height: normal;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
`

export const EditableRowValue = styled.div`
  flex: 1;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const EditableRowAction = styled.div`
  color: ${colors['grey']};
  margin-left: auto;
`
export const EditableRowConfirmation = styled.div`
  margin: 0 10px;

  ${mobileFirstMedia.phone`
    margin-left: 126px;
  `}

  ${media.screen`
    & > div {
      width: 100%;
    }
  `}
`
