import { handleActions } from 'redux-actions'
import createActionFactory from 'utils/createActionFactory'

export const types = {
  AUTH: 'AUTH',
  LOGIN: 'LOGIN',
  LOGIN_V2: 'LOGIN_V2',
  LOGIN_ICON: 'LOGIN_ICON',
  LOGIN_PASSWORD: 'LOGIN_PASSWORD',
  REGISTER: 'REGISTER',
  REGISTER_V2: 'REGISTER_V2',
  GUEST: 'GUEST',
  GUEST_V2: 'GUEST_V2',
  SMS_VERIFY: 'SMS_VERIFY',
  SMS_SEND_CODE: 'SMS_SEND_CODE',
  SEARCH: 'SEARCH',
  FORGOT: 'FORGOT',
  FORGOT_SENT: 'FORGOT_SENT',
  FORGOT_V2: 'FORGOT_V2',
  FORGOT_V2_SENT: 'FORGOT_V2_SENT',
  PARTY_NOTIF: 'PARTY_NOTIF',
  PARTY_INVITE: 'PARTY_INVITE',
  PARTY_LOGIN: 'PARTY_LOGIN',
  ORDER_PARTY_GENERIC_MODAL: 'ORDER_PARTY_GENERIC_MODAL',
  ORDER_PARTY_MEMBER_LEFT_BEHIND: 'ORDER_PARTY_MEMBER_LEFT_BEHIND',
  ORDER_PARTY_ALERT: 'ORDER_PARTY_ALERT',
  HAS_ACTIVE_ORDER_PARTY_MODAL: 'HAS_ACTIVE_ORDER_PARTY_MODAL',
  SERVING_TO_TABLE: 'SERVING_TO_TABLE',
  UNAVAILABLE_ITEM: 'UNAVAILABLE_ITEM',
  GENERIC: 'GENERIC',
  EMAIL_UPDATED: 'EMAIL_UPDATED',
  PAYMENT_SOURCE: 'PAYMENT_SOURCE',
  CARD_ADDER: 'CARD_ADDER',
  NOTIFICATION_MODAL: 'NOTIFICATION_MODAL',
}

const ca = createActionFactory('unicorn/modals/')
export const setData = ca('SET_DATA')
export const hide = ca('HIDE')
export const show = ca('SHOW', (visible, data) => ({ visible, data }))

const initState = {
  visible: null,
  data: null,
}

export default handleActions(
  {
    [hide]: () => initState,
    [show]: (state, { payload: { visible, data } }) => ({ visible, data }),
    [setData]: (state, { payload }) => ({ ...state, data: payload }),
  },
  initState,
)

export const selectVisibleModal = state => state.modals.visible
export const selectData = state => state.modals.data
