import client from 'client'
import { fetchNonce } from './heyyou'
import config from 'app-config'
import { trackEvent } from 'utils/firebase'
import events from 'const/analytics-events'
import get from 'lodash/get'
export const submit = ({
  venueId,
  items,
  serviceType,
  orderNote,
  payment,
  seatingInfo,
  orderPartyId,
  customerId,
  address,
  mobile,
  customer,
  orderType,
  queryString,
}) =>
  fetchNonce().then(nonce =>
    client()
      .post(
        `/orders/submit${queryString}`,
        {
          nonce,
          venueId,
          items,
          serviceType,
          orderNote,
          payment,
          seatingInfo,
          orderPartyId,
          customerId,
          address,
          mobile,
          customer,
          ...(orderType && {
            orderType,
          }),
        },
        {
          ...(config.ORDER_SUBMIT_VERSION === '3' && {
            headers: {
              'Accept-Version': config.ORDER_SUBMIT_VERSION,
            },
          }),
        },
      )
      .then(resp => {
        trackEvent({
          ...events.RECEIVED_ORDER_SUBMIT_RESPONSE,
          vendor_id: venueId,
          order_id: get(resp,'data.orderId'),
          customer_id: customerId,
          order_status: get(resp,'data.status.code'),
          http_code: get(resp,'status')
        })
        return config.ORDER_SUBMIT_VERSION === '3'
          ? resp.data
          : orderPartyId
          ? orderPartyId
          : resp.data.orderId
      }).catch(err => {
        trackEvent({
          ...events.RECEIVED_ORDER_SUBMIT_RESPONSE,
          vendor_id: venueId,
          customer_id: customerId,
          http_code: get(resp,'status'),
          status_code: get(resp,'code')
        })
        return err
      })
  )

export const multiVendorSubmit = ({
  cart,
  serviceType,
  orderType,
  payment,
  address,
  mobile,
  customer,
}) =>
  fetchNonce().then(nonce =>
    client()
      .post(
        '/orders/multi/submit',
        {
          nonce,
          cart,
          serviceType,
          orderType,
          payment,
          address,
          mobile,
          customer,
        },
        {
          ...(config.ORDER_SUBMIT_VERSION === '3' && {
            headers: {
              'Accept-Version': config.ORDER_SUBMIT_VERSION,
            },
          }),
        },
      )
      .then(resp =>
        config.ORDER_SUBMIT_VERSION === '3' ? resp.data : resp.data.orderId,
      ),
  )

export const fetchStatus = (orderId, partyId) =>
  client().get(
    `/orders/submit/status/${orderId}${
      partyId ? '?orderPartyId=' + partyId : ''
    }`,
  )

export const fetchAnonymousOrderStatus = orderId =>
  client().get(`/orders/${orderId}/submit/status/anonymous`)

export const fetchOrder = orderId => client().get(`/orders/${orderId}`)

export const fetchMultiVendorOrder = cartId =>
  client().get(`/orders/multi/${cartId}`)

export const fetchMultiOrderStatus = orderId =>
  client().get(`/orders/multi/${orderId}/submit/status`)

export const fetchOrderHistory = (params = {}) => {
  return client().get('/customers/me/history', { params })
}

export const sendReceipt = params => {
  return client().post('/customers/me/receipt', params)
}
