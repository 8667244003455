export const MEMBER_JOINED = 'MEMBER_JOINED'

export const ORDER_SUBMITTED = 'ORDER_SUBMITTED'

export const ORDER_PAID = 'ORDER_PAID'

export const ORDER_PLACED = 'ORDER_PLACED'

export const ACCEPTED = 'ACCEPTED'

export const MEMBER_ORDER_ADDED = 'MEMBER_ORDER_ADDED'

export const DECLINED = 'DECLINED'

export const MEMBER_LEFT = 'MEMBER_LEFT'

export const CLOSED = 'CLOSED'

export const AWAITING_PAYMENT = "AWAITING_PAYMENT"

export const PAYMENT_FAILED = 'PAYMENT_FAILED'

export const CANCELLED = 'CANCELLED'