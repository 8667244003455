import React, { PureComponent } from 'react'

import { compose } from 'recompose'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import { selectProfile, updateProfile } from 'auth/modules/profile'

import { logout } from 'auth/modules/auth'
import withNotifications from 'enhancers/withNotifications'

import EditField from '../EditField'
import get from 'lodash/get'
import { show, types } from 'store/modals'

class EditEmailContainer extends PureComponent {
  onUpdateField = async data => {
    try {
      await this.props.updateProfile(data)
      this.props.show(types.EMAIL_UPDATED)
      this.props.logout()
    } catch (err) {
      const errorToMessage = err => {
        const { code, message } = get(err, 'response.data.status')
        switch (code) {
          case 130101:
            return message
          default:
            return `Invalid ${this.props.label}`
        }
      }

      this.props.notify({
        type: 'error',
        message: errorToMessage(err),
      })
      throw err
    }
  }

  render() {
    const { label, name, email, secure } = this.props

    return (
      <EditField
        label={label}
        name={name}
        value={email}
        onSubmit={this.onUpdateField}
        notify={this.props.notify}
        secure={secure}
      />
    )
  }
}

const componentSelector = createSelector(selectProfile, ({ email }) => ({
  email,
}))

const withRedux = connect(componentSelector, {
  updateProfile,
  logout,
  show,
})

export default compose(withNotifications, withRedux)(EditEmailContainer)
