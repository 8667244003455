import { delay } from 'redux-saga'
import { put, select, takeLatest } from 'redux-saga/effects'
import { pullStatus } from 'store/order-party/order-party.constants'
import { smartSelectOrderPartyByCode } from 'store/order-party/order-party.selectors'
import get from 'lodash/get'
import values from 'lodash/values'
import find from 'lodash/find'

import OrderPartyApi from 'api/order-party'
import { smartSelectOrderById } from 'pages/order/modules/order'
import { detachParty } from '../../store/order-party/order-party.actions'
import * as orderApi from 'api/order'
import { show, types } from 'store/modals'
import notif from 'services/notification'
import last from 'lodash/last'
import isEmpty from 'lodash/isEmpty'
import { selectProfile } from 'auth/modules/profile'
import { MEMBER_JOINED, ORDER_SUBMITTED, ORDER_PAID, ORDER_PLACED, ACCEPTED, MEMBER_ORDER_ADDED, DECLINED, MEMBER_LEFT, CLOSED, AWAITING_PAYMENT, PAYMENT_FAILED, CANCELLED } from './const'

export default [
  takeLatest(String(pullStatus), function* pollStatusOnCheckout({
    payload: partyCode,
    meta: { resolve },
  }) {
    try {
      const res = yield OrderPartyApi.getOrderPartyStatus(partyCode)

      const currentOrderParty = yield select(
        smartSelectOrderPartyByCode,
        partyCode,
      )

      const profile = yield select(selectProfile)

      // handle notification
      if (
        get(res, 'members', []).length >
          get(currentOrderParty, 'members', []).length &&
        !isEmpty(currentOrderParty)
      ) {
        const newMember = last(get(res, 'members', []))
        yield put(
          show(types.ORDER_PARTY_ALERT, {
            type: MEMBER_JOINED,
            memberName: get(newMember, 'customerName'),
          }),
        )
      }

      if (
        [ORDER_SUBMITTED, ORDER_PAID, ORDER_PLACED].includes(
          get(res, 'leaderStatus'),
        ) &&
        get(currentOrderParty, 'leaderStatus') === 'ACCEPTED' &&
        get(res, 'leaderCustomerId') !== get(profile, 'id')
      ) {
        yield put(
          show(types.ORDER_PARTY_ALERT, {
            type: ORDER_PLACED,
            hostName: get(res, 'leaderCustomerName'),
            venueId: get(res, 'vendorId'),
          }),
        )
      }

      yield* get(res, 'members', []).map(function*(member, i) {
        if (get(member, 'customerId') !== get(profile, 'id')) {
          if (
            member.status === ORDER_PLACED &&
            get(currentOrderParty, `members.${i}.status`) === ACCEPTED &&
            get(member, 'customerId') &&
            get(currentOrderParty, `members.${i}.customerId`)
          ) {
            yield put(
              show(types.ORDER_PARTY_ALERT, {
                type: MEMBER_ORDER_ADDED,
                memberName: get(member, 'customerName'),
                venueId: get(res, 'vendorId'),
              }),
            )
          }
          if (
            member.status === DECLINED &&
            get(currentOrderParty, `members.${i}.status`) === ACCEPTED &&
            get(member, 'customerId') &&
            get(currentOrderParty, `members.${i}.customerId`)
          ) {
            yield put(
              show(types.ORDER_PARTY_ALERT, {
                type: MEMBER_LEFT,
                memberName: get(member, 'customerName'),
              }),
            )
          }
          if (
            member.status === ACCEPTED &&
            get(currentOrderParty, `members.${i}.status`) === DECLINED &&
            get(member, 'customerId') &&
            get(currentOrderParty, `members.${i}.customerId`)
          ) {
            yield put(
              show(types.ORDER_PARTY_ALERT, {
                type: MEMBER_JOINED,
                memberName: get(member, 'customerName'),
              }),
            )
          }
        }
      })

      const { members, ...orderStatus } = yield !get(
        currentOrderParty,
        'salesOrderId',
      )
        ? {}
        : orderApi
            .fetchStatus(
              get(currentOrderParty, 'salesOrderId'),
              get(currentOrderParty, 'id'),
            )
            .then(res => res.data)

      const placedOrder = yield select(
        smartSelectOrderById,
        get(currentOrderParty, 'vendorId'),
      )

      const currentOrder = find(
        values(get(placedOrder, 'placedOrders', [])),
        order =>
          get(currentOrderParty, 'members', []).find(
            member =>
              get(member, 'salesOrderId') === get(order, 'orderId') ||
              get(order, 'orderId') === get(currentOrderParty, 'salesOrderId'),
          ),
      )

      resolve()

      if (
        [ACCEPTED].includes(get(currentOrderParty, 'leaderStatus')) &&
        [ORDER_PLACED, ORDER_SUBMITTED, ORDER_PAID].includes(
          get(res, 'leaderStatus'),
        ) &&
        get(res, 'memberStatus') === ACCEPTED
      ) {
        yield put(
          show(types.ORDER_PARTY_MEMBER_LEFT_BEHIND, {
            venueId: get(res, 'vendorId', ''),
            orderPartyId: get(res, 'id'),
            isMember: true,
          }),
        )
      }

      if (get(res, 'status') === CLOSED) {
        const orderMembers = get(res, 'members')
        const isMember = find(orderMembers, { customerId: profile.id })

        if (isMember)
          notif.error({
            title: 'Host cancelled group order.',
            hasCloseButton: true
          })
        
        yield put(detachParty(get(currentOrderParty, 'vendorId')))
      } else {
        if (
          (get(res, 'memberStatus') !== DECLINED &&
            get(res, 'leaderStatus') === ACCEPTED) ||
          (get(res, 'memberStatus') !== ACCEPTED &&
            [ORDER_PLACED, ORDER_SUBMITTED, ORDER_PAID].includes(
              get(res, 'leaderStatus'),
            ))
        ) {
          yield put(
            pullStatus.fulfilled({
              payload: {
                ...res,
                partyCode,
                ...(get(currentOrderParty, 'salesOrderId') && {
                  orderStatus: [
                    {
                      ...orderStatus,
                      ...get(orderStatus, 'status', {}),
                      isLeader: true,
                    },
                    ...(members || []),
                  ],
                }),
              },
            }),
          )
        }
  
        if (
          get(currentOrder, 'status', []).includes(ACCEPTED) ||
          get(currentOrder, 'status', []).includes(PENDING) ||
          get(currentOrder, 'status', []).includes(AWAITING_PAYMENT) ||
          [ORDER_PAID, ORDER_SUBMITTED, ORDER_PLACED].includes(
            get(res, 'memberStatus'),
          ) ||
          (get(currentOrderParty, 'orderStatus') &&
            !get(currentOrderParty, 'orderStatus.0.flags', []).includes(
              PAYMENT_FAILED,
            )) ||
          (get(res, 'memberStatus') === ACCEPTED &&
            ![ORDER_PAID, ORDER_SUBMITTED, ORDER_PLACED].includes(
              get(res, 'leaderStatus'),
            ))
        ) {
          // Wait for 1 second before fetching data again
          yield delay(2000)
          yield put(pullStatus(partyCode))
        }
      }
    } catch (error) {
      const currentOrderParty = yield select(
        smartSelectOrderPartyByCode,
        partyCode,
      )

      const leaderStatus = get(currentOrderParty, 'leaderStatus')

      if (
        [ORDER_PLACED, ORDER_SUBMITTED].includes(leaderStatus) ||
        [ORDER_PLACED, ORDER_SUBMITTED].includes(
          get(currentOrderParty, 'memberStatus'),
        )
      ) {
        yield put(
          pullStatus.rejected({
            payload: {
              ...currentOrderParty,
              hasError: true,
            },
          }),
        )
      }

      if (
        get(error, 'response.data.status.code') === 100 &&
        leaderStatus === ACCEPTED
      ) {
        yield put(
          show(types.ORDER_PARTY_ALERT, {
            type: CANCELLED,
            hostName: get(currentOrderParty, 'leaderCustomerName'),
            venueId: get(currentOrderParty, 'vendorId'),
          }),
        )
        yield put(detachParty(get(currentOrderParty, 'vendorId')))
      }
    }
  }),
]
