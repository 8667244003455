import {
  login,
  selectError,
  selectIsAuthenticating,
  clearError,
} from 'auth/modules/auth'
import { connect } from 'react-redux'
import { compose, withProps, withState } from 'recompose'
import get from 'lodash/get'
import Cookies from 'js-cookie'

import notif from 'services/notification'
import { show, hide, types } from 'store/modals'
import { unsetGuestCheckoutEmail } from 'store/guest-checkout'
import {
  withFormValidator,
  isRequired,
  isEmail,
  composeValidators as composeV,
} from 'utils/validator'
import getHistory from 'services/history'
import { COOKIE_REDIRECT_PATH } from 'const/cookie'
import { lookup } from 'api'
import noScroll from 'no-scroll'

export default compose(
  connect(
    state => ({
      asyncError: selectError(state),
      isAuthenticating: selectIsAuthenticating(state),
    }),
    (dispatch, { onSuccess = () => {} }) => ({
      onSubmit: ({ email, password }) =>
        dispatch(login(email, password))
          .then(onSuccess)
          .then(dispatch(unsetGuestCheckoutEmail()))
          .then(() => notif.success({ title: 'Login successful' }))
          .then(() => {
            localStorage.removeItem('dormant-user')
            const redirectPath = Cookies.get(COOKIE_REDIRECT_PATH)
            if (redirectPath) {
              Cookies.remove(COOKIE_REDIRECT_PATH)
              getHistory().push(redirectPath)
            }
          })
          .then(noScroll.off())
          .catch(err => {
            notif.error({
              title: get(err, 'response.data.status.message', 'Login failed!'),
              hasCloseButton: true,
            })

            const errorCode = get(err, 'response.data.status.code', 400)
            const errorMessage = get(err, 'response.data.status.message')
            
            if (errorCode === 240010) return dispatch(
              show(types.NOTIFICATION_MODAL, {
                text: errorMessage,
                buttonLabel: 'Done',
                onHideAction: () => {
                  localStorage.setItem('dormant-user', email)
                  window.location.replace('/reset-account')
                },
              }),
            )

            notif.error({
              title: get(
                err,
                'response.data.status.message',
                'Login failed!',
              ),
              title: errorMessage || 'Login failed!',
              hasCloseButton: true,
            })
            
          }),
      clearAsyncError: () => dispatch(clearError()),
      showForgotPassModal: () => dispatch(show(types.FORGOT_V2)),
    }),
  ),

  withProps({
    onSubmitFail: () =>
      notif.error({
        title: 'Login failed',
        message: 'please check the form errors',
      }),
    verifyEmail: ({ email, setState, show }) => {
      setState({
        current: 'email',
        isLoading: true,
      })
      lookup({ username: email })
        .then(res => {
          if (res.usernameExists) {
            setState({
              current: 'password',
              isLoading: false,
            })
          } else {
            show(types.REGISTER_V2)
          }
        })
        .catch(err => {
          setState({
            current: 'email',
            isLoading: false,
          })
        })
    },
  }),
  withState('state', 'setState', {
    current: 'email',
    isLoading: false,
  }),

  withFormValidator({
    email: composeV(isRequired, isEmail)('Email'),
    password: isRequired('Password'),
  }),
)
