import React, { Component } from 'react'

import PT from 'prop-types'
import serialize from 'form-serialize'
import Input, { InputButton } from 'components.v2/Input'
import Icon from 'components.v2/Icon'
import Spinner from 'components.v2/Spinner'

import EditableRow from './../../components/EditableRow'
import { emailRegex } from 'utils/validator'

class EditField extends Component {
  form = React.createRef()
  /**
   * we can also use object notation for responsivenes
   * default: 'large',
   * tablet: 'regular',
   * screen: 'regular',
   */
  inputConfig = 'regular'

  state = {
    isSubmitting: false,
    isEditing: false,
    hasError: false,
  }

  static propTypes = {
    label: PT.string,
    name: PT.string,
    value: PT.string,
    onSubmit: PT.func,
    secure: PT.bool,
  }

  toggle = () => {
    if (this.state.isSubmitting) return
    this.setState({
      isEditing: !this.state.isEditing,
      hasError: false,
    })
  }

  validate = e => {
    const value = e.target.value

    if (!value) {
      this.setState({ hasError: true })
      this.props.notify({
        type: 'error',
        message: 'Email field is required',
      })
      return
    }

    if (!emailRegex.test(value.toLowerCase()) && e.target.name === 'email') {
      this.setState({ hasError: true })
      this.props.notify({
        type: 'error',
        message: 'Invalid email format',
      })
      return
    }
  }

  handleSubmit = async event => {
    try {
      event.preventDefault()

      this.setState({ hasError: false })

      const data = serialize(this.form.current, { hash: true })

      if (!data[this.props.name]) {
        this.setState({ hasError: true })
        return
      }

      if (
        !emailRegex.test(data[this.props.name].toLowerCase()) &&
        this.props.name === 'email'
      ) {
        this.setState({ hasError: true })
        this.props.notify({
          type: 'error',
          message: `Invalid email format`,
        })
        return
      }

      this.setState({ isSubmitting: true })

      await this.props.onSubmit(data)

      this.setState({ isSubmitting: false, isEditing: false })
    } catch (err) {
      this.setState({ hasError: true, isSubmitting: false })
      return
    }
  }

  getStateIcon() {
    if (this.state.isSubmitting) {
      return <Spinner variant="alt-primary" />
    } else {
      return <Icon type="arrowRight" size={20} fill="white" />
    }
  }

  getForm() {
    return (
      this.state.isEditing && (
        <form onSubmit={this.handleSubmit} ref={this.form} autoComplete="off">
          <Input
            autoFocus
            name={this.props.name}
            defaultValue={this.props.secure ? '' : this.props.value}
            size={this.inputConfig}
            disabled={this.state.isSubmitting}
            hasError={this.state.hasError}
            onBlur={this.validate}
            rightContent={
              <InputButton
                disabled={this.state.isSubmitting}
                onClick={this.handleSubmit}
              >
                {this.getStateIcon()}
              </InputButton>
            }
            autoComplete="off"
          />
        </form>
      )
    )
  }

  render() {
    return (
      <EditableRow
        label={this.props.label}
        value={this.props.value}
        isEditing={this.state.isEditing}
        actions={[
          {
            label: 'Edit',
            handler: this.toggle,
          },
        ]}
        onCancel={this.toggle}
        secure={this.props.secure}
      >
        {this.getForm()}
      </EditableRow>
    )
  }
}

export default EditField
