import React from 'react'
import { connect } from 'react-redux'
import PT from 'prop-types'
import { hide, show } from 'store/modals'
import StylableModal from 'components.v2/Modal/StylableModal'
import styled from 'styled-components'

const Modal = styled(StylableModal)`
  &__content {
    position: relative;
    display: block;
    width: 470px;
    outline: none !important;
    padding: 40px 24px;
  }

  ,
  &__overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.44);
    position: fixed;
    z-index: 10000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

const Layout = styled.div`
  display: flex;
  padding: 32px 20px 20px 20px;
  flex-direction: column;
  width: 422px;

  border-radius: 12px;
  background: var(--White, #fff);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
  gap: 32px;

  & > .content {
    color: var(--Grey-2, #999);
    text-align: center;

    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }

  & > .button {
    border-radius: 8px;
    background: var(--Orange-0, #f26722);
    height: 52px;
    color: var(--White, #fff);
    text-align: center;

    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;

    :focus {
      outline: none;
    }
  }
`

const NotificationModal = ({
  text,
  hide,
  onHideAction,
  buttonLabel,
  customButton,
}) => {
  const handleHide = () => {
    hide()
    onHideAction && onHideAction()
  }

  return (
    <Modal
      isOpen
      contentLabel="Serving To Table"
      onRequestClose={handleHide}
      className="text-center pb-20 px-20"
      small
      noCloseButton
    >
      <Layout>
        {text && <div className="content">{text}</div>}
        {customButton ? (
          <customButton />
        ) : (
          <button className="button" onClick={handleHide}>
            {buttonLabel ? buttonLabel : 'Ok'}
          </button>
        )}
      </Layout>
    </Modal>
  )
}

NotificationModal.propTypes = {
  handleHide: PT.func,
  text: PT.string,
}

const withContainer = connect(() => ({}), { show, hide })

export default withContainer(NotificationModal)
