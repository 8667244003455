export const USER_SELECTED_AT_LEAST_ONCE_CLICK_TO_PAY = 'user_selected_at_least_once_click_to_pay'
export const FZ_CLICK_TO_PAY_HOSTED_PAYMENT = 'fz_click_to_pay_hosted_payment'

export const PAYMENT_SOURCE_TYPE = {
  APPLE_PAY: 'applepay',
  GOOGLE_PAY: 'googlepay',
  CLICK_TO_PAY: 'clicktopay',
  STRIPE: 'stripe',
}
  
export const PAYMENT_SOURCE_NAME = {
  APPLE_PAY: 'apple pay',
  GOOGLE_PAY: 'google pay',
  CLICK_TO_PAY: 'click to pay',
  STRIPE: 'stripe',
}