import React, { PureComponent } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import styled from 'styled-components'
import { fonts } from 'theme/fonts'
import colors from 'theme/colors'
import { media } from 'utils/media'

import { selectProfile, sendConfirmEmail } from 'auth/modules/profile'
import Icon from 'components.v2/Icon/IconV2'
import notif from 'services/notification'
import withNotifications from 'enhancers/withNotifications'

const EmailConfirmWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  border: 1px dashed ${colors['orange']};
  border-radius: 12px;
  background: #fdf0e9;
  padding: 12px 16px;
  max-width: 1180px;
  margin: 0 auto 20px;
  width: 100%;

  ${media.screen`
    width: calc(100% - 30px);
  `}

  svg path {
    fill: ${colors['orange']};
  }

  & > p {
    font-size: ${fonts['smallText']['size']}px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 16px;
    color: ${colors['orange']};
    flex: 1;
  }

  & > button {
    font-size: ${fonts['smallText']['size']}px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 16px;
    background: ${colors['orange']};
    color: ${colors['white']};
    border-radius: 8px;
    text-wrap: nowrap;
    padding: 6px 0;
    width: 110px;

    &:disabled {
      opacity: 0.65;
    }
  }
`

class EmailConfirmBanner extends PureComponent {
  state = {
    confirmSent: false,
    isConfirmSending: false,
  }

  handleSendConfirm = async event => {
    event.preventDefault()
    this.setState({ isConfirmSending: true })

    try {
      await this.props.sendConfirmEmail()

      notif.success({ title: 'Confirmation email sent' })
    } catch (err) {
      const { message } = get(err, 'response.data.status')

      this.props.notify({
        type: 'error',
        message: message,
      })
    }

    this.setState({ confirmSent: true, isConfirmSending: false })
  }

  render() {
    const { flags, email } = this.props
    const isConfirmed = flags?.includes('EMAIL_VERIFIED')

    if (isConfirmed || !email) return <></>

    return (
      <EmailConfirmWrapper>
        {this.state.confirmSent && <Icon src="Tick.svg" />}
        <p>
          {this.state.confirmSent
            ? "Check your inbox - we've sent you a confirmation email."
            : 'Help keep your account secure by confirming your email address'}
        </p>
        <button
          onClick={this.handleSendConfirm}
          disabled={this.state.isConfirmSending}
          style={{ visibility: this.state.confirmSent ? 'hidden' : 'initial' }}
        >
          {this.state.isConfirmSending ? 'Sending...' : 'Confirm now'}
        </button>
      </EmailConfirmWrapper>
    )
  }
}

const componentSelector = createSelector(selectProfile, ({ email, flags }) => ({
  email,
  flags,
}))

const withRedux = connect(componentSelector, {
  sendConfirmEmail,
})

export default compose(withNotifications, withRedux)(EmailConfirmBanner)
