import styled from 'styled-components'
import media from 'utils/media'
import StylableModal from 'components.v2/Modal/StylableModal'

export const ImageContainer = styled.img`
  width: 248px;
`

// Order Party Button
export const OrderPartyButton = styled.button`
  display: flex;
  gap: 8px;

  color: var(--White, #fff);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;

  height: 36px;
  padding: 8px 16px;
  border-radius: 8px;
  background: var(--Orange--1, #d95c1e);
  outline: none;
  &:focus {
    outline: none;
  }

  ${media.phone`
    position: fixed;
    bottom: 15px;
    left: 0;
    z-index: 10;
    width: calc(100% - 30px);
    margin: 0 15px;
    justify-content: center;
  `}
`

// Order Party Modal
export const Modal = styled(StylableModal)`
  &__content {
    position: relative;
    display: block;

    background-color: ${p => p.theme.colors.white};
    outline: none !important;
    border-radius: 12px;
    overflow-y: auto;
    width: 100%;
    max-width: 600px;
    max-height: calc(100vh - 36px);

    ${media.screen`
      max-width: 100%;
      max-height: 100%;
      height: 100%;
      border-radius: 0px;
    `}
  }

  &__overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    z-index: 10000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

export const Container = styled.div`
  height: auto;
  width: auto;
  padding: 32px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  overflow: auto;

  & > div {
    display: flex;
    gap: 32px;
    flex-direction: column;
    width: 100%;
  }

  & > div > button {
    height: 52px;
    outline: none;
  }
`

export const InviteHeader = styled.div`
  color: #000;
  text-align: center;

  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;

  ${media.phone`
    font-size: 24px;
    line-height: 28px;
  `}
`

export const InviteSubHeader = styled.div`
  color: var(--Grey-1, #666);
  text-align: center;

  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;

  ${media.phone`
    font-size: 14px;
    line-height: 20px;
  `}
`

export const ContentRow = styled.div`
  border-radius: 8px;
  background: var(--Grey-5, #f2f2f2);
  height: 68px;

  display: flex;
  padding: 14px 20px;
  gap: 20px;

  align-items: center;

  & > .col > .header {
    color: #000;

    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  & > .col > .subheader {
    color: var(--Grey-1, #666);

    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  & > .col {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    overflow: hidden;
  }

  &.not-authenticated > .col > .subheader {
    color: #000;

    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
  }

  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export const InviteButton = styled.button`
  width: 100%;

  color: var(--White, #fff);
  text-align: center;

  border-radius: 8px;
  background: #f26722;

  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;

  outline: none;
`

export const CancelButton = styled.button`
  color: var(--Orange-0, #f26722);
  text-align: center;
  width: 100%;

  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;

  outline: none;
`

export const InviteLink = styled.div`
  & > input {
    color: #000;

    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.14px;
    width: 100%;
    height: 100%;
    background: #f2f2f2;
    outline: none;
  }

  padding: 14px 20px;
  background: #f2f2f2;
  border-radius: 12px;

  display: flex;
  flex-direction: row;

  align-items: center;
`

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
`

// Error Modal
export const ErrorModal = styled(StylableModal)`
  &__content {
    position: relative;
    display: block;
    max-width: 340px;
    background-color: ${p => p.theme.colors.white};
    outline: none !important;
    border-radius: 12px;
    width: 100%;
    overflow-y: hidden;
    ${media.phone`
      width: 100%;
    `}
  }

  &__overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    z-index: 10000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

export const ErrorContainer = styled.div`
  padding: 32px 20px;

  & > .subheader {
    color: var(--Grey-1, #666);
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.14px;

    padding-top: 12px;
  }

  & > .title {
    color: var(--Black, #000);
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
    letter-spacing: 0.18px;
  }

  & > .button-container {
    padding-top: 32px;
    display: flex;
    flex-direction: row;
    gap: 12px;

    & > button {
      height: 52px;
      width: 144px;
      border-radius: 8px;
      text-align: center;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.14px;

      outline: none;
    }

    & > button.dismiss {
      background: #fdf0e9;
      color: #f26722;
    }
    & > button.view-group {
      background: #f26722;
      color: #ffffff;
    }
  }
`

// Leave or cancel order party
export const LeaveOrderPartyButton = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-top: 40px;
  margin-bottom: 30px;

  & > a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;

    cursor: pointer;

    color: var(--Grey-2, #999);
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
`

export const PartyHeader = styled.div`
  background: var(--Black, #000);
  height: 60px;
`
